<template>
  <div class="blank"></div>
</template>

<script>
  export default {
    name: 'Blank',
    mounted() {
      console.log('Blank');
    },
  };
</script>

<style scoped>

</style>
